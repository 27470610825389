<template>
<div id="fever">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%; max-width: 1294px; margin:0 auto">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">オンラインで診療</div>
          </div>
          <div class="separator" style="color: #000000;">
            <div  class="content1" style="padding-bottom: 30px; width: 60%;margin-left: 20%;">
              <div>
                <div class="side-box" style="width: 100%;">
                  <div style="width: 60%;">
                  <div class="text1">当院では、LOOMEの遠隔診療システムを導入しております。 </div>
                  <div class="text2">通常の来院予約及びオンライン診療の予約も承っております。 </div>
                  <div class="text3">※初診の方は、オンライン診療はお受けできません。 </div>
                  <div class="text4">予約の流れ</div>
                  <div class="text5">① スマートフォンで右のQRを読み込み</div>
                  <div class="text6">② 新規登録またはログインをする</div>
                  <div class="text7">③ ホーム画面の「予約受付」ボタンからご予約</div>
                  <div class="text8">④ 当院から予約確定のメールが届いたら予約完了となります。</div>
                  <div class="text9">アプリの操作に関するお問い合わせ</div>
                  <div class="text10">TEL: 03-6260-6081（10:00～17:00）</div>
                  <div class="text11">MAIL: info@rapha-d.com</div>
                  <!-- <div class="btn">
                    <el-button round>LOOME 操作マニュアル PDF</el-button>
                  </div> -->
                </div>
                <div class="side-img" style="position: absolute; left:55%">
                  <img src="../../assets/departments/rapha99_QR.jpg" alt="" width="220">
                  <div class="link" @click="download(PatientManual, '患者用操作マニュアル.pdf')">患者用操作マニュアル<br>ダウンロード</div>
                </div>
                </div>
              </div>
            </div>
              
          </div>
        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <div style="width: 100%; max-width: 1294px; margin:0 auto">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">オンラインで診療</div>
          </div>
          <div class="separator" style="color: #000000;">
            <div  class="content1" style="padding-bottom: 30px; width: 86%;margin-left: 7%;">
              <div>
                <div style="width: 100%;">
                  <div style="width: 100%;">
                  <div class="text1">当院では、LOOMEの遠隔診療システムを導入しております。 </div>
                  <div class="text2">通常の来院予約及びオンライン診療の予約も承っております。 </div>
                  <div class="text3">※初診の方は、オンライン診療はお受けできません。 </div>
                  <div class="text4">予約の流れ</div>
                  <div class="text5">① スマートフォンで右のQRを読み込み</div>
                  <div class="text6">② 新規登録またはログインをする</div>
                  <div class="text7">③ ホーム画面の「予約受付」ボタンからご予約</div>
                  <div class="text8">④ 当院から予約確定のメールが届いたら予約完了となります。</div>
                  <div class="text9">アプリの操作に関するお問い合わせ</div>
                  <div class="text10">TEL: 03-6260-6081（10:00～17:00）</div>
                  <div class="text11">MAIL: info@rapha-d.com</div>
                  <!-- <div class="btn">
                    <el-button round>LOOME 操作マニュアル PDF</el-button>
                  </div> -->
                </div>
                <div style="width: 70%; margin-left: 15%; margin-top: 30px;">
                  <img src="../../assets/departments/rapha99_QR.jpg" alt="" width="220" class="fit_img">
                  <div class="m_link" @click="download(PatientManual, '患者用操作マニュアル.pdf')">患者用操作マニュアル<br>ダウンロード</div>
                </div>
                </div>
              </div>
            </div>
              
          </div>
        </div>
    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
//import { classof } from "core-js/core/object";
export default {
  name: "Fever",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader},
  data(){
    return{
      PatientManual: './secondFile/患者用操作マニュアル.pdf',
    }
  },
  methods:{
    download(path, title) {
      let a = document.createElement('a')
      a.setAttribute('href', path)
      a.setAttribute('download', title)
      document.body.appendChild(a)
      a.click()
    }
  }
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
      margin-top: 40px;
    }

    .content_text{
        margin-top: 18px;
    }
.title1 {
      color: #e5d05e;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .text1 {
      color: #535353;
      line-height: 1.5;
      margin-bottom: 15px;
    }
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
      height:5px;
      border-top-color: #e5d05e;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }
    

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #e5d05e;
  border-bottom: 2px solid #e5d05e;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #f2e289;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

/*診療時間*/
.table table {
  border-collapse: collapse;
  color: #535353;
  border-spacing: 0;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
}
.table table tr:first-child {
  background-color: #f0f0f0;
}
.table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 16px;
}

.table_m table {
  border-collapse: collapse;
  color: #535353;
  border-spacing: 0;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.table_m table tr:first-child {
  background-color: #f0f0f0;
}
.table_m table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

    .separator {
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

    .side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }

    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }
    
    
    .side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
  
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

.side-line-highlight{
  background-color: #e5d05e;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.link{
  background-color: #62b6e2;
  color: #ffffff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}
.m_link{
  background-color: #62b6e2;
  color: #ffffff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}

.link:hover{
  background-color: #ffffff;
  color: #62b6e2;
}



</style>
